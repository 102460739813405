<template>
    <div class="pb-20 pt-20">
     <div class="container mx-auto grid grid-cols-1 p-3 sm:w-full md:w-5/12">

            <div class="bg-white rounded-md shadow-md p-5">
                <div class="text-lg font-semibold">
                    Tentang RIZKI
                </div>
                <div class="border-gray-200 mt-3 mb-2">
                    <h3>Yayasan Rumah Infak dan Zakat Indonesia atau disingkat RIZKI didirikan di Kabupaten Jember, berdasarkan Akta Nomor. 10 tanggal 5 Mei 2003 yang dibuat di hadapan notaris Is Hariyanto Imam Salwawi, SH. Dengan dinamisasi dan tuntutan perkembangannya dilakukan perubahan Akta pendirian Nomor 01 tanggal 1 Februari 2011 yang dibuat dihadapan notaris Irwan Rosman, SH., MKn. dengan nama Yayasan Rumah Itqon Zakat dan Infak (RIZKI). Sedangkan pengesahan dari Menteri Hukum dan Hak Asasi Manusia Republik Indonesia dengan nomor: AHU-6120.AH.01.04 TH 2011 tanggal 15 September 2011.
<br><br> Yayasan RIZKI berkedudukan di Jl. Karimata Gg. 4, Lingkungan Krajan Barat, Kelurahan Sumbersari, Kec. Sumbersari, Kab. Jember Provinsi Jawa Timur pada mulanya merupakan lembaga sosial keagamana, dengan kiprah dan aktivitasnya di tengah masyarakat. Namun seiring berjalannya waktu serta aspirasi dari masyarakat akhirnya Yayasan RIZKI memantapkan diri sebagai lembaga zakat.
<br><br> Dan tahun 2017 turunlah rekomendasi BAZNAS Nomor : 526/ HVR/SDP/BAZNAS/XI/2017, yang kemudian ditindak lanjuti dengan penetapan sebagai Lembaga Amil Zakat Daerah Kab. Jember dengan SK Kementerian Agama Provinsi Jawa Timur Nomor 3436 Tahun 2018.</h3>
                </div><br>

                <div class="text-lg font-semibold pb-3">
                    Struktur Kepengurusan
                    <!-- <img src="/muslim.png" alt=""> -->
                </div>
                 <br> 
                <div class="text-lg font-semibold pb-3">
                    Temukan Kami
                </div>
                <div class="text-center">
                <b-col md="3">
              <ul class="list-unstyled">
              <a href="https://www.facebook.com/Lembaga-Amil-Zakat-Rizki-677232912677027/" class="btn-floating p-2 btn-sm text-gray" style="font-size: 23px;"><span class="fab fa-facebook"></span></a>
              <a href="https://twitter.com/laz_rizki" class="btn-floating p-2 btn-sm text-gray" style="font-size: 23px;"><span class="fab fa-twitter"></span></a>
              <a href="http://Wa.me/6285335674030" class="btn-floating p-2 btn-sm text-gray" style="font-size: 23px;"><span class="fab fa-whatsapp"></span></a>
              <a href="https://www.instagram.com/zakat_rizki/" class="btn-floating p-2 btn-sm text-gray" style="font-size: 23px;"><span class="fab fa-instagram"></span></a>
              <a href="https://www.youtube.com/channel/UCDHgEHd2uixavG3dEqs8H0A/featured" class="btn-floating p-2 btn-sm text-gray" style="font-size: 23px;"><span class="fab fa-youtube"></span></a>
              <a href="https://gorizki.org/" class="btn-floating p-2 btn-sm text-gray" style="font-size: 23px;"><span class="fas fa-globe"></span></a>
            </ul>

            <!-- <ul class="list-inline-item">
              <a
                href="https://www.facebook.com/Lembaga-Amil-Zakat-Rizki-677232912677027/"
                class="btn-floating btn-sm text-gray"
                style="font-size: 23px;"
                ><i
                  class="fab 
            fa-facebook"
                ></i
              ></a>
            </ul>
            <ul class="list-inline-item">
              <a
                href="https://www.instagram.com/zakat_rizki/"
                class="btn-floating btn-sm text-gray"
                style="font-size: 23px;"
                ><i
                  class="fab 
            fa-instagram"
                ></i
              ></a>
            </ul>
            <ul class="list-inline-item">
              <a
                href="https://twitter.com/laz_rizki"
                class="btn-floating btn-sm text-gray"
                style="font-size: 23px;"
                ><i
                  class="fab 
            fa-twitter"
                ></i
              ></a>
            </ul> -->
        </b-col>
      </div>

                <!-- <div v-html="campaign.description"></div> -->
            </div>

        </div>
    </div>
</template>